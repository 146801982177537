import de from './de'
import en from './en';
import el from './el';
import {languages} from '../lang'


let i18n = {
  de, 
  en,
  el
}

export const __  = (phrase, t) => {
  if(t === 'default'){
    if(en[phrase]){
      return en[phrase]
    }
  }
  let translation
  translation = phrase;

  {languages.forEach(el => {
    if(i18n[el] && i18n[el][phrase]){
      translation = i18n[el][phrase]
    }
  })}

  return translation

}

let linksMapping = {
  'el': {
    'how-to-use-it': 'odigies',
    'our-technology': 'technologia',
    'contact': 'epikoinonia',
    'pricing': 'oi-times-mas',
    'why-us': 'ti-prosferoume',
    'privacy-policy': 'politiki-aporritou'
  }
}

export const __link = (link, t = 'default') => {

  console.log('link', link, t)
  if(t === 'default'){
    return link
  }

  let i18nLink
  i18nLink = link;

  if(linksMapping[t] && linksMapping[t][link]){
    i18nLink = linksMapping[t][link]
  }
  console.log(i18nLink, 'link')

  return i18nLink
}
