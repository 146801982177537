export default {
  "Home Page": "Home Page",
  "Create Account": "Create Account",
  "Our Technology": "Our Technology",
  "Our technology": "Our technology",
  "Technology Subtitle":
    "Early detection of plant stress is a unique AgriTech break through innovation service that only farmAIr can provide.",
  "Technology Subtitle 2":
    "farmAIr technology combines thermal and visual images, machine learning and Artificial Intelligence, in order to reveal Plant Stress, before any symptom is expressed, helping for preemptive measures that will minimize costs. We employ drones, equipped with dual cameras for remote sensing and aerial photography scanning and diagnosis of your field.",
  "Innovation Subtitle":
    "Unlike what is currently available in the market, farmAIr technology (patented) use thermal images and Artificial Intelligence, in order to reveal Plant Stress, before the onset of any symptom. We help farmers and agronomists to spot what they can't see with the naked eye, so as to be aware and take all necessary precautions and actions, that will help prevent any spread. At the moment, farmAIr technology is available for vineyards, planning to gradually expand to virtually any plant with leaves",

  "What we offer": "What we offer",
  "We Offer Subtitle":
    "farmAIr is a dynamic, flexible and customer-oriented AgriTech company, committed to always bring cutting-edge technology and the best experience to you.",

  "Create your account": "Create your account",
  "You need just 1 minute to register so don’t lose time and be ahead with our technology. Maintain & Improve the health of your crop":
    "You need just one minute to register, so don’t waste time and step into the future, using our technology. Monitor the progress of your crops, and ultimately improve their overall health and efficiency.",
  "Add your Fields": "Add your Field",
  "Add your Fields Subtitle":
    "Select your field on the satellite map with our easy-to-use drawing tool and provide us with some additional information about your vineyard (more crops are coming soon).",
  "Collect images by drone surveying or Upload your images.":
    "Choose between our End to End or Bring Your Own Images packages.",
  "Either you book one of our experts to check your crop or you can upload your images.":
    "You can either enjoy the full package, by booking the drone surveying of your field with us, or take over aerial photo shooting yourself, and upload your own images – just contact us beforehand in order to comply with our image spec requirements.",
  "AI processing": "AI processing",
  "AI processing Subtitle":
    "Our sophisticated AI algorithms will process the images of your field. It is not magic, it is the new age, cutting edge technology that farmAIr is bringing to you now, saving you time, money and effort.",
  "Check Result": "Check the Results",
  "Check Result Subtitle":
    "You can check the results from anywhere. You just need an internet connection. Login to your account and check your report to see if you have any stressed plants in your field. Go back to past reports anytime to identify any patterns. Is that simple!",
  "Navigate to": "Navigate to each stressed plant",
  "Navigate to Subtitle":
    "You have stressed plants that you can’t see with your naked eye? No need to worry! Click on the pins on your field’s report, see the picture and simply walk to the stressed plant. The distance calculator will help you locate the exact position on the map.",

  Innovation: "Innovation",

  "How to use it": "How to use the service",
  "Sign Up": "Sign Up",
  "Learn More": "Learn More",
  "Our Platform Subtitle":
    "Gain access to your field data from your mobile, tablet and desktop. Our digital platform is a secure and easy to use software application designed to simplify crop scouting.",
  "Our platform": "Our Platform",
  "Our Partners": "In cooperation with:",
  "Our Partners Subtitle": "Our strong and valuable partners and supporters",
  "Read our blog": "Read our blog",
  "Our Blog Subtitle":
    "Read our latest news and our actions in Agriculture Technology Ecosystem.",
  "Why us": "Why us",
  Pricing: "Pricing",
  Contact: "Contact",
  "Sign In": "Sign in",
  "Sign in": "Sign in",
  "Sign up": "Sign up",
  "Pricing Subtitle":
    "Select among our complete End to End service (currently for selected countries and above certain land-size, but please contact us if you do not comply, and we may find a way together) or the ability to Bring and Upload your Own Images. For both packages get advantage of our discounts on longer-term services and large size and adjust the final price based on the number of the annual reports you need.",
  Register: "Register",
  "Our Tech": "Our Tech",
  "How to": "How to",
  "Our blog": "Our blog",
  "Contact us": "Contact us",
  "Contact us Subtitle":
    "We currently specialize in vines (more crops are coming soon). Don't hesitate to contact us for any question you have or any clarification you may need and we will certainly get back to you.",
  "Offices:": "Offices:",
  "Sales Support:": "Sales Support:",
  "Tel:": "Tel:",
  "Full Name": "Full Name",
  "Job Description": "Subject",
  "Write your message": "Write your message",
  "Send mail": "Send mail",

  "Tech Title 1": "Visual Image",
  "Tech Title 2": "Thermal Image",
  "Tech Title 3": "Image Fusion",
  "Tech Title 4": "Artificial Intelligence identifies the problem",
  "Tech Title 5": "Our technology will make you see",
  "Tech Title 6": "30 days later",
  "Tech Description 1":
    "We utilize the full potential of high-resolution visual aerial photographs to isolate the plants from the soil.",
  "Tech Description 2":
    "We use thermal aerial photographs in order to understand from the leaves’ relative temperature if there is stress.",
  "Tech Description 3":
    "Our machine learning algorithms combine visual and thermal images, creating a new set of images of pseudo-color-indicated sub-areas, for every plant having stress (if any).",
  "Tech Description 4":
    "Our Object Detection algorithms automatically produce delimited sub-areas of the plant(s) having stress.",
  "Tech Description 5":
    "Plant stress is not visible to the naked eye in the early stages, but as of today, you are able to know well in advance. Thanks to our pseudo-color image, you are able to clearly indicate the problem well in advance and act, giving you time to act proactively, and conserve considerable costs.",
  "Tech Description 6":
    "The problem which our technology revealed 30 days before, is now evident on the infected plan, since no action had been taken in that case.",

  "We Offer Title 1": "Weather Data",
  "We Offer Title 2": "Early & Accurate detection of plant stress (Pat)",
  "We Offer Title 3": "Soil Data",
  "We Offer Title 4": "Stress Classification",
  "We Offer Title 5": "Vegetation and Water content Indexes",
  "We Offer Title 6": "3D Model of each field",
  "We Offer Title 7": "Low Chlorophyll Content",
  "We Offer Title 8": "Navigate to the problematic plant(s)",
  "We Offer Title 9": "Digital Surface Map",
  "We Offer Title 10": "Comprehensive Stress Detection Report",

  "We Offer Description 1":
    "Live, historical (up to 7 days before the shooting), and a 3-day forecast of temperature, wind speed, barometric pressure, moisture, precipitation, and cloud coverage – absolute values & graphs.",
  "We Offer Description 2":
    "Biotic and Abiotic Plant stress detection, using high-definition thermal and digital cameras farmAIr’s Artificial Intelligence.",
  "We Offer Description 3":
    "Moisture & temperature of the soil surface and the temperature at 10 cm below the soil surface, 2 times per day & historical data (at the shooting day) -  absolute values & graphs.",
  "We Offer Description 4":
    "Combining our ”Early & Accurate detection of Biotic & Abiotic plant stress” technology(Pat) together with Water & Chlorophyll Content – data collected using high-resolution aerial images with accuracy on a leaf level - as well as Soil and Meteorological data, we offer on a pilot basis but in high accuracy, the classification of the stress detected on your plant(s) – We classify Fungal Diseases, Trunk Diseases, Water Stress, Toxicity, Heat Stress.",
  "We Offer Description 5":
    "Satellite images and graphs (daily and historical data at +/- 8 days from the shooting day) of the EVI (Enhanced  Vegetation Index), NDVI (Normalized Difference Vegetation Index) & NDWI (Normalized Difference Water Index).",
  "We Offer Description 6":
    "Accurate illustration of the field and its vines in high accuracy georeferenced 3D model – Our virtual remote navigation allows you to monitor your plants' development after each photo shooting.",
  "We Offer Description 7":
    "High-definition depiction of the low chlorophyll content areas of the field – The low chlorophyll content at non-stressed plants is a possible indication of nitrogen fertilization need.",
  "We Offer Description 8":
    "Navigate using your cell phone or tablet in those areas requiring your attention - High accuracy georeferenced images (GPS), navigation deviation of less than 10 cm.",
  "We Offer Description 9":
    "High-definition depiction of the height difference within the field and of each individual vine trunk – as an indication of potential irrigation or drainage issues.",
  "We Offer Description 10":
    "Download your all-inclusive summary pdf report after the photoshoot of your filed, see all the findings, key data & forecasts in one place and share it with your scientific team.",

  "Basic Package": "Basic Package",
  "from €100 per year": "from €100 per year",
  "End to End: €120/hectare": "End to End: €120/hectare",
  "Manual Upload Images: €100/hectare": "Bring Your Own Images: €100/hectare",
  "Num of Shoots": "Num of Shoots",
  "3 shots per year": "3 shots per year",
  "Service Types": "Service Types",
  "Duration:": "Duration:",
  "1 year": "1 year",
  total: "total",
  "End to End": "End to End",
  Manual: "Bring Your Own Images",
  "Duration of the Package": "Duration of the Package",
  "1 year": "1 year",
  "2 years": "2 years",
  "3 years": "3 years",
  "Number Of Shoots": "Number Of Shoots",
  "Number of Hectares": "Number of Hectares",
  Calculator: "Calculator",
  "Intro Title": "Early Detection of Plant Stress",
  "Intro Subtitle":
    "Preventing and minimizing the Cost of Cure of the Worlds' Plants",
  "Tel Number": "+1 (302) 464-8072",
  Accept: "Accept",
  Decline: "Decline",
  "Cookie consent text":
    "This website uses cookies to enhance the user experience.",
  Prices: "Pricing",
  "Privacy Policy": "Privacy Policy",
  "I agree with the": "I agree with the ",
  or: "or",
  "You are saving": "You are saving",
}
