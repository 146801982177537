import React from "react"
import Navigation from './Navigation'
import Footer from './Footer'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { __, __link } from 'utils/i18n'
import StoryblokService from '../utils/storyblok-service'
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { getLangLinkPrefix } from 'utils/lang'
import farmairLogoBg from 'images/logo512.png';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default function Layout({ children, location, lang, t, story }) {
  const { settings } = useStaticQuery(graphql`
  query Settings {
    settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
      edges {
        node {
          name
          full_slug
          content
        }
      }
    }
  } 
  `)
  let { pathname } = location
  let language = pathname.split("/")[1].replace('/', '')
  let activeLanguage = ['de', 'en'].includes(language) ? language : 'en'
  let correctSetting = settings.edges.filter(edge => edge.node.full_slug.indexOf(activeLanguage) > -1)
  let hasSetting = correctSetting && correctSetting.length ? correctSetting[0].node : {}
  let content = typeof hasSetting.content === 'string' ? JSON.parse(hasSetting.content) : hasSetting.content
  let parsedSetting = Object.assign({}, content, { content: content })

  const theLang = getLangLinkPrefix(t);

  const onAcceptGdprCookies = () => {
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    Cookies.set("gatsby-gdpr-facebook-pixel", true, {
      expires: nextYear
    });
  }

  return (
    <div className="bg-light farmair-app">
      <GatsbySeo
        title='farmAIr | Early Detection of Plant Stress'
        description="Preventing and minimizing the Cost of Cure of the Worlds' Plants"
        canonical='https://farmair.io/'
        openGraph={{
          url: 'https://farmair.io/',
          title: 'farmAIr | Early Detection of Plant Stress',
          description: "Preventing and minimizing the Cost of Cure of the Worlds' Plants",
          images: [
            {
              url: `https://farmair.io${farmairLogoBg}`,
              width: 512,
              height: 512,
              alt: 'farmAIr.io',
            },
          ],
          site_name: 'farmAIr.io',
        }}
        twitter={{
          site: '@farmair_io',
          cardType: 'summary_large_image',
        }}
      />

      <Helmet
        script={[
          {
            "src": `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.token}`,
            "type": "text/javascript"
          }
        ]}
      />
      <Helmet
        script={[
          {
            "innerHTML": `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
            "type": "text/javascript"
          }
        ]}
      />
      {/* <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="agritech, drone, agriculture, artificial intelligence, crop scouting, app, farm, field" />

        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />


        {/* <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://www.spookytech.com",
              "name": "Spooky technologies",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+5-601-785-8543",
                "contactType": "Customer Support"
              }
            }
          `}
        </script> */}
      {/* </Helmet> */}
      <Navigation settings={parsedSetting} lang={activeLanguage} t={t} />
      <main>
        {children}
      </main>
      <Footer t={t} />
      <section className="cookie-consent-wrap">
        <div className="inner">
          <CookieConsent
            disableStyles={true}
            // location="bottom"
            buttonClasses="m-btn m-btn-sm"
            buttonText={__('Accept', t)}
            cookieName="gatsby-gdpr-google-analytics"
            expires={365}
            buttonWrapperClasses="buttons-wrap"
            buttonClasses="m-btn m-btn-sm xs m-btn-yellow-outline"
            containerClasses="consent-wrap"
            contentClasses="content-wrap"
            declineButtonText={__('Decline', t)}
            enableDeclineButton
            declineButtonClasses="m-btn m-btn-sm xs m-btn-white-outline"
            onAccept={onAcceptGdprCookies}
          >
            {__('Cookie consent text', t)}&nbsp;<span className="privacy-link">
              <Link to={`${theLang}/${__link('privacy-policy', t)}`}>
                {__("Privacy Policy", t)}
              </Link></span>
          </CookieConsent>
        </div>
      </section>

    </div>
  )
}