export const languages = ['en', 'de', 'it', 'el']


export const getLangLinkPrefix = (t) => {
  let prefix = ''
  if(t === 'default'){
    return prefix;
  }

  languages.forEach(el => {
    if(t == el){
      prefix = `/${el}`
    }
  })
  return prefix;
}
