import React from "react"
import SbEditable from "storyblok-react"
import { Link } from "gatsby"
import { __, __link } from "utils/i18n"
import { getLangLinkPrefix } from "utils/lang"
import "styles/component/Footer.style.scss"
import ContactForm from "component/ContactForm"
import youtube from "images/youtube.svg"
import linkedin from "images/linkedin.svg"
import facebook from "images/facebook.svg"
import twitter from "images/twitter.svg"
import instagram from "images/instagram.svg"

const Footer = ({ t }) => {
  const lang = getLangLinkPrefix(t)
  return (
    <footer className="bg-green sec-pad-std">
      <ContactForm t={t} />

      <nav className="">
        <div className="container">
          <div className="row">
            <div className="col-md-6 left">
              <div className="row">
                <div className="col-md-4">
                  <ul>
                    <li>
                      <Link to={`${lang}/${__link("how-to-use-it", t)}`}>
                        {__("How to use it", t)}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${lang}/${__link("why-us", t)}`}>
                        {__("Why us", t)}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${lang}/${__link("our-technology", t)}`}>
                        {__("Our technology", t)}
                      </Link>
                    </li>
                    {/* <li><Link to={`${lang}/${__link('pricing', t)}`}>{__("Pricing", t)}</Link></li> */}
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul>
                    <li>
                      <Link to={`${lang}/${__link("contact", t)}`}>
                        {__("Contact", t)}
                      </Link>
                    </li>
                    <li>
                      <Link to={`${lang}/${__link("privacy-policy", t)}`}>
                        {__("Privacy Policy", t)}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul>
                    <li>
                      <Link to={`${lang}/${__link("blog", t)}`}>
                        {__("Blog", t)}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 right">
              <div className="inner">
                <div className="btn-wrap">
                  <div className="sign-in-wrap">
                    <a
                      href="https://app.farmair.io/login"
                      target="_blank"
                      rel="noreferrer"
                      className="m-btn m-btn-white m-btn-sm sign-in"
                    >
                      {__("Sign in", t)}
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://app.farmair.io/signup"
                      target="_blank"
                      rel="noreferrer"
                      className="m-btn m-btn-white m-btn-sm"
                    >
                      {__("Sign up", t)}
                    </a>
                  </div>
                </div>
                <div className="social-wrap">
                  <ul>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/farmair/"
                        rel="noreferrer"
                        target="_blank"
                        title="Linkedin"
                      >
                        <img src={linkedin} alt="linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/farmAIr.io/"
                        rel="noreferrer"
                        target="_blank"
                        title="facebook"
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/farmair_io"
                        rel="noreferrer"
                        target="_blank"
                        title="twitter"
                      >
                        <img src={twitter} alt="twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UC8qtc1xr3ucmMvv0hWThEqA"
                        rel="noreferrer"
                        target="_blank"
                        title="youtube"
                      >
                        <img src={youtube} alt="youtube" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/farmair.io/"
                        rel="noreferrer"
                        target="_blank"
                        title="instagram"
                      >
                        <img src={instagram} alt="instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12">
              <p className="copyright">
                Copyright &#169; 2020 -2021 farmAIr, Inc. - All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </nav>
    </footer>
  )
}

export default Footer
