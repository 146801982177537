export default {
  "Home Page": "Home Page",
  "Create Account": "Δημιουργία Λογαριασμού",
  "Our Technology": "Η Τεχνολογία μας",
  "Our technology": "Η Tεχνολογία μας",
  "Technology Subtitle":
    "Η Έγκαιρη Ανίχνευση του Στρες των Φυτών είναι μια καινοτόμα υπηρεσία Αγροτεχνολογίας που μόνο η farmAIr μπορεί να προσφέρει μέχρι στιγμής.",
  "Technology Subtitle 2":
    "Η τεχνολογία της farmAIr χρησιμοποιεί θερμικές και ψηφιακές φωτογραφίες, μηχανική μάθηση και Τεχνητή Νοημοσύνη για να αποκαλύψει το Στρες των Φυτών, πριν την έκφραση οποιονδήποτε ορατών συμπτωμάτων. Χρησιμοποιούμε drones εξοπλισμένα με θερμική και ψηφιακή κάμερα για την αεροφωτογράφιση, σάρωση και διάγνωση του χωραφιού σας.",
  "Innovation Subtitle":
    "Σε αντίθεση με ό,τι υπάρχει μέχρι σήμερα στην αγορά, η τεχνολογία της farmAIr, η οποία αποτελεί κατοχυρωμένη ευρεσιτεχνία, χρησιμοποιεί θερμικές φωτογραφίες και τεχνολογία Τεχνητής Νοημοσύνης, για να αποκαλύψει το Στρες των Φυτών, πριν από οποιαδήποτε έκφραση ορατών συμπτωμάτων. Βοηθούμε τους παραγωγούς και τους γεωπόνους να εντοπίσουν ό,τι δεν είναι εμφανές με γυμνό μάτι, έτσι ώστε να γνωρίζουν και να λάβουν τα κατάλληλα μέτρα, προλαμβάνοντας έγκαιρα οποιαδήποτε εξάπλωση, εξοικονομώντας σημαντικά κόστη. Αρχικά εξειδικευόμαστε στο αμπέλι και σύντομα σκοπεύουμε να επεκταθούμε και σε άλλες καλλιέργειες, καλύπτοντας σχεδόν κάθε φυτό που έχει φύλλα.",

  "What we offer": "Τι προσφέρουμε",
  "We Offer Subtitle":
    "Η farmAIr είναι μια δυναμικά αναπτυσσόμενη πελατοκεντρική εταιρία Αγροτεχνολογίας.  Δέσμευσή μας είναι να προσφέρουμε στον αγρότη προηγμένη τεχνολογία και την καλύτερη δυνατή εμπειρία από τις υπηρεσίες μας.",

  "Create your account": "Δημιουργήστε το λογαριασμό σας",
  "You need just 1 minute to register so don’t lose time and be ahead with our technology. Maintain & Improve the health of your crop":
    "Χρειάζεστε μόλις 1 λεπτό για να εγγραφείτε, οπότε μη χάνετε χρόνο. Εκμεταλλευτείτε την πρωτοποριακή τεχνολογία μας και παρατηρείστε την εξέλιξη της υγείας του χωραφιού σας, έτσι ώστε να είστε πάντοτε ένα βήμα μπροστά.",
  "Add your Fields": "Προσθέστε το χωράφι σας",
  "Add your Fields Subtitle":
    "Επιλέξτε το χωράφι σας στο δορυφορικό χάρτη, χρησιμοποιώντας το εύχρηστο εργαλείο σχεδίασής μας, παρέχοντας μερικές επιπλέον πληροφορίες για τον αμπελώνα σας - σύντομα διαθέσιμο και για άλλες καλλιέργειες.",
  "Collect images by drone surveying or Upload your images.":
    "Διαλέξτε μεταξύ των υπηρεσιών Ολοκληρωμένης Κάλυψης ή απλής Διάγνωσης",
  "Either you book one of our experts to check your crop or you can upload your images.":
    "Μπορείτε να επιλέξετε το πλήρες πακέτο υπηρεσιών μας και να αναλάβουμε εμείς τη φωτογραφική κάλυψη του χωραφιού σας, τις ημερομηνίες που σας εξυπηρετεί, με τους πιστοποιημένους πιλότους drone που διαθέτουμε, ή εναλλακτικά, να αναλάβετε εσείς την αεροφωτογράφιση και την παροχή των κατάλληλων εικόνων – σε αυτή την περίπτωση, επικοινωνήστε μαζί μας για να σας πούμε τις τεχνικές προδιαγραφές που χρειαζόμαστε.",
  "AI processing": "Επεξεργασία Τεχνητής Νοημοσύνης",
  "AI processing Subtitle":
    "Οι εξελιγμένοι μας αλγόριθμοι θα επεξεργαστούν τις εικόνες του χωραφιού σας, εντοπίζοντας το πρόβλημα. Δεν είναι μαγικό, είναι απλά η νέα τεχνολογία αιχμής που σας προσφέρει η farmAIr σήμερα, εξοικονομώντας σας χρόνο, κόπο και χρήματα.",
  "Check Result": "Δείτε τα αποτελέσματα",
  "Check Result Subtitle":
    "Μπορείτε να δείτε τα αποτελέσματα από οπουδήποτε: το μόνο που χρειάζεστε είναι σύνδεση στο διαδίκτυο. Συνδεθείτε στο λογαριασμό σας και ελέγξτε την αναφορά μας για να δείτε εάν έχετε πρέμνα που υπόκεινται σε στρες στο χωράφι σας. Επιστρέψτε σε παλαιότερες αναφορές ανά πάσα στιγμή για να εντοπίσετε πιθανά μοτίβα. Είναι τόσο απλό!",
  "Navigate to": "Πλοηγειθείτε σε κάθε φυτό που χρίζει της προσοχής σας",
  "Navigate to Subtitle":
    "Έχετε φυτά με στρες που δεν είναι εμφανή με γυμνό μάτι;  Μην ανησυχείτε πλέον! Κάντε click στα Pins της αναφοράς μας, δείτε τις φωτογραφίες και πηγαίντε κατευθείαν στο προβληματικό πρέμνο. Ο υπολογιστής απόστασης θα σας βοηθήσει να εντοπίσετε την ακριβή θέση του στο χάρτη.",

  Innovation: "Καινοτομία",

  "How to use it": "Οδηγίες Χρήσης",
  "Sign Up": "Εγγραφείτε",
  "Learn More": "Μάθετε Περισσότερα",
  "Our Platform Subtitle":
    "Αποκτήστε πρόσβαση στα δεδομένα του χωραφιού σας από το κινητό, το tablet ή τον υπολογιστή σας. Η ψηφιακή μας πλατφόρμα αποτελεί μια ασφαλής και εύκολη στη χρήση εφαρμογή, σχεδιασμένη έτσι ώστε να απλοποιεί την παρακολούθηση της υγείας της καλλιέργειάς σας.",
  "Our platform": "Η Πλατφόρμα μας",
  "Our Partners": "Σε συνεργασία:",
  "Our Partners Subtitle":
    "Οι ισχυροί και πολύτιμοι συνεργάτες και υποστηριχτές μας",
  "Read our blog": "Διαβάστε το blog μας",
  "Our Blog Subtitle":
    "Διαβάστε τα τελευταία νέα μας και τις ενέργειές μας στον τομέα της Αγροτεχνολογίας",
  "Why us": "Γιατί εμάς",
  Pricing: "Τιμολογιακή Πολιτική",
  Contact: "Επικοινωνία",
  "Sign In": "Συνδεθείτε",
  "Sign in": "Συνδεθείτε",
  "Sign up": "Εγγραφείτε",
  "Pricing Subtitle":
    "Επιλέξτε μεταξύ της υπηρεσίας Ολοκληρωμένης Κάλυψης (διαθέσιμη για την ώρα για συγκεκριμένες χώρες και πάνω από ένα συγκεκριμένο μέγεθος χωραφιού – αν δεν εμπίπτετε όμως στα συγκεκριμένα κριτήρια, μη διστάσετε να επικοινωνήσετε μαζί μας και είναι πιθανό να βρούμε τη λύση μαζί) ή της απλής Διάγνωσης. Και για τα δύο πακέτα υπηρεσιών, επωφεληθείτε από τις εκπτώσεις μακροχρόνιων συμβάσεων και μεγάλων εκτάσεων και αναπροσαρμόστε την τελική τιμή ανάλογα με τον αριθμό των καλύψεων που χρειάζεστε ανά έτος.",
  Register: "Εγγραφείτε",
  "Our Tech": "Η Τεχνολογία μας",
  "How to": "Οδηγίες",
  "Our blog": "Το blog μας",
  "Contact us": "Επικοινωνήστε μαζί μας",
  "Contact us Subtitle":
    "Για την ώρα εξειδικευόμαστε στα αμπέλια (σύντομα θα επεκταθούμε και σε άλλες καλλιέργειες). Μη διστάσετε να επικοινωνήσετε μαζί μας για οποιαδήποτε απορία ή διευκρίνιση χρειαστείτε και θα επανέλθουμε",
  "Offices:": "Offices:",
  "Sales Support:": "Υποστήριξη πωλήσεων:",
  "Tel:": "Τηλ:",
  "Full Name": "Oνοματεπώνυμο",
  "Job Description": "Θέμα",
  "Write your message": "Γράψτε το μήνυμά σας",
  "Send mail": "Αποστολή Email",

  "Tech Title 1": "Ψηφιακή φωτογραφία",
  "Tech Title 2": "Θερμική εικόνα φωτογραφία",
  "Tech Title 3": "Σύντηξη εικόνων",
  "Tech Title 4": "Η Τεχνητή Νοημοσύνη εντοπίζει το πρόβλημα",
  "Tech Title 5": "Η τεχνολογία μας θα σας βοηθήσει να δείτε",
  "Tech Title 6": "30 μέρες μετά",
  "Tech Description 1":
    "Χρησιμοποιούμε ψηφιακή αεροφωτογραφία υψηλής ανάλυσης για να απομονώσουμε τα φυτά από το έδαφος.",
  "Tech Description 2":
    "Χρησιμοποιούμε θερμική αεροφωτογραφία για να αναγνωρίσουμε μέσω της σχετικής θερμοκρασίας των φύλλων την ύπαρξη στρες στα φυτά.",
  "Tech Description 3":
    "Οι αλγόριθμοι μηχανικής μάθησης συνδυάζουν τις ψηφιακές με τις θερμικές φωτογραφίες, έτσι ώστε να δημιουργήσουν ένα νέο σύνολο εικόνων στο οποίο προσδιορίζονται με ψευδοχρωματισμό οι υπο-περιοχές των φυτών που υπόκεινται σε στρες (εφόσον αυτές υπάρχουν).",
  "Tech Description 4":
    "Οι αλγόριθμοι Ανίχνευσης Αντικειμένων δημιουργούν αυτόματα τις οριοθετημένες υπο-περιοχές των πρέμνων που υπόκεινται σε στρες.",
  "Tech Description 5":
    "Το Στρες των Φυτών δεν είναι ορατό με γυμνό μάτι στα αρχικά του στάδια, αλλά από σήμερα, μπορείτε να γνωρίζετε με ασφάλεια και εκ των προτέρων. Χάρη στις φωτογραφίες με τις ενδείξεις ψευδοχωρματισμού, έχετε τη δυνατότητα να δείτε ξεκάθαρα το πρόβλημα πολύ πριν την έκφραση οποιαδήποτε ορατών συμπτωμάτων έτσι ώστε να λάβετε τα κατάλληλα μέτρα, εξοικονομώντας σημαντικά κόστη.",
  "Tech Description 6":
    "Το πρόβλημα που εντόπισε η τεχνολογία μας προ 30 ημερών, είναι πλέον ορατό στο προσβεβλημένο πρέμνο, αφού στη συγκεκριμένη περίπτωση δεν έγινε κάποια ενέργεια για την αντιμετώπιση του προβλήματος.",

  "We Offer Title 1": "Μετεωρολογικά ∆εδομένα",
  "We Offer Title 2": "Έγκαιρη ∆ιάγνωση Βιοτικού  & Αβιοτικού Στρες",
  "We Offer Title 3": "∆εδομένα Εδάφους",
  "We Offer Title 4": "Κατηγοριοποίηση του είδους του στρες",
  "We Offer Title 5": "∆είκτες Βλάστησης και Περιεκτικότητας Νερού",
  "We Offer Title 6": "Τρισδιάστατη απεικόνιση του χωραφιού",
  "We Offer Title 7": "∆είκτης Χαμηλής Περιεκτικότητας Χλωροφύλλης",
  "We Offer Title 8": "Εύρεση των προβληματικών πρέμνων",
  "We Offer Title 9": "Υψομετρική διαφορά χωραφιού και πρέμνων",
  "We Offer Title 10": "Συγκεντρωτική Έκθεση ∆ιάγνωσης",

  "We Offer Description 1":
    "Live, ιστορικά (μέχρι 7 ημέρες πριν από την ημερομηνία  λήψεως) και προβλέψεις (3 ημερών) θερμοκρασίας, εντάσεως  ανέμου, βαρομετρικής πίεσης, υγρασίας, υετού και νεφοκάλυψης - απόλυτες τιμές & γραφήματα.",
  "We Offer Description 2":
    "Ανίχνευση & ∆ιάγνωση του στρες, με τη χρήση θερμικής  & ψηφιακής κάμερας υψηλής ευκρίνειας και την επεξεργασία τους από την Τεχνητή Νοημοσύνη της farmAIr.",
  "We Offer Description 3":
    "2 φορές τη μέρα & ιστορικές τιμές (της ημερομηνίας  αεροφωτογράφισης) υγρασίας & θερμοκρασίας εδάφους,  καθώς και θερμοκρασίας υπεδάφους (-10 εκατοστά) -  απόλυτες τιμές & γραφήματα.",
  "We Offer Description 4":
    "Συνδυάζοντας την τεχνολογία της «Έγκαιρης ∆ιάγνωσης  Βιοτικού & Αβιοτικού Στρες»(Pat) με την περιεκτικότητα  νερού & χλωροφύλλης - στοιχεία που λήφθηκαν από τις  υψηλής ανάλυσης αεροφωτογραφίες σε επίπεδο φύλλου,  αλλά και ∆εδομένα Εδάφους & Μετεωρολογικά ∆εδομένα,  σας δίνουμε πιλοτικά με μεγάλη αποτελεσματικότητα, και την κατηγοριοποίηση του στρες που εντοπίσαμε στα  πρέμνα σας (Μυκητολογικές Ασθένειες, Ασθένειες ξύλου,  Υδατικό Στρες, Τοξικότητα, Θερμικό Στρες).",
  "We Offer Description 5":
    "∆ορυφορικές φωτογραφίες, αλλά και γραφήματα τιμών  (καθημερινά αλλά και ιστορικές τιμές, +/- 8 μέρες από την  ημερομηνία αεροφωτογράφισης) των δεικτών EVI (Enhanced  Vegetation Index), NDVI (Normalized Difference Vegetation  Index) & NDWI (Normalized Difference Water Index).",
  "We Offer Description 6":
    "Απεικόνιση του χωραφιού και των πρέμνων σε  τρισδιάστατο μοντέλο με υψηλής ακρίβειας γεωαναφορά -  περιηγηθείτε εξ αποστάσεως στο χωράφι σας για να δείτε  την ανάπτυξη των πρέμνων.",
  "We Offer Description 7":
    "Υψηλής ευκρίνειας απεικόνιση της έλλειψης χλωροφύλλης  του χωραφιού - Η χαμηλή ένδειξη χλωροφύλλης σε μη  στρεσαρισμένα φυτά είναι πιθανή ένδειξη ανάγκης για  αζωτούχα λίπανση.",
  "We Offer Description 8":
    "Πλοηγηθείτε με το κινητό ή το tablet σας σε κάθε  σημείο που χρίζει της προσοχής σας - νέα, βελτιωμένη  γεωαναφορά υψηλής ακρίβειας (GPS), πλοήγηση με  απόκλιση κάτω των 10 εκατοστών.",
  "We Offer Description 9":
    "Υψηλής ευκρίνειας απεικόνιση της υψομετρικής διαφοράς  του χωραφιού και του κάθε πρέμνου μεμονωμένα – ένδειξη  για πιθανό στραγγιστικό πρόβλημα.",
  "We Offer Description 10":
    "Μετά από κάθε λήψη, λαμβάνετε συγκεντρωτική   έκθεση (pdf) με όλα τα βασικά ευρήματα της ∆ιάγνωσης,  για να τα μοιραστείτε με την επιστημονική σας ομάδα.",

  "Basic Package": "Βασικό πακέτο",
  "from €100 per year": "από €100 ετησίως",
  "End to End: €120/hectare": "Ολοκληρωμένη Κάλυψη: €120/εκτάριο",
  "Manual Upload Images: €100/hectare": "Απλή Διάγνωση: €100/εκτάριο",
  "Num of Shoots": "Αριθμός λήψεων",
  "3 shots per year": "3 λήψεις ετησίως",
  "Service Types": "Τύποι υπηρεσιών",
  "Duration:": "Διάρκεια:",
  "1 year": "1 χρόνος",
  total: "σύνολο",
  "End to End": "Ολοκληρωμένη Κάλυψη",
  Manual: "Απλή Διάγνωση",
  "Duration of the Package": "Διάρκεια πακέτου",
  "1 year": "1 χρόνος",
  "2 years": "2 χρόνια",
  "3 years": "3 χρόνια",
  "Number Of Shoots": "Αριθμός λήψεων",
  "Number of Hectares": "Αριθμός εκταρίων",
  Calculator: "Υπολογισμός Τιμής",
  "Intro Title": "Έγκαιρη Ανίχνευση του Στρες των Φυτών",
  "Intro Subtitle":
    "Πρόληψη για την ελαχιστοποίηση του κόστους θεραπείας των φυτών",
  "Tel Number": "+30 210 22 03 167",
  Accept: "Αποδοχή",
  Decline: "Απόρριψη",
  "Cookie consent text":
    "Αυτός ο ιστότοπος χρησιμοποιεί cookies για να βελτιώσει την εμπειρία του χρήστη.",
  Prices: "Τιμές",
  "Privacy Policy": "Πολιτική Απορρήτου",
  "I agree with the": "Συμφωνώ με την ",
  or: "ή",
  "You are saving": "Έκπτωση",
}
