const path = require('path')
/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.com/docs/gatsby-config/
 */

module.exports = {
  siteMetadata: {
    siteUrl: 'https://www.farmair.io'
  },
  plugins: [
    'gatsby-plugin-remove-console',
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://www.farmair.io',
        sitemap: 'https://www.farmair.io/sitemap.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    },
    `gatsby-plugin-sitemap`,
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-next-seo',
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: "farmAIr | Early Detection of Plant Stress",
        short_name: "farmAIr",
        start_url: "/",
        background_color: "#ffffff",
        theme_color: "#1c4d00",
        // Enables "Add to Homescreen" prompt and disables browser UI (including back button)
        // see https://developers.google.com/web/fundamentals/web-app-manifest/#display
        display: "standalone",
        icon: "src/images/logo.png", // This path is relative to the root of the site.
        // An optional attribute which provides support for CORS check.
        // If you do not provide a crossOrigin option, it will skip CORS for manifest.
        // Any invalid keyword or empty string defaults to `anonymous`
        // crossOrigin: `use-credentials`,
      },
    },
    `gatsby-plugin-offline`,
    // `gatsby-plugin-remove-serviceworker`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: path.join(__dirname, `src`, `images`),
      },
    },
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sass',
    'gatsby-plugin-postcss',
    {
    resolve: 'gatsby-source-storyblok',
    options: {
      accessToken: 'qsYQmrfbrCeQQ5NZQzAsOgtt',
      homeSlug: 'home',
      version: process.env.NODE_ENV === 'production' ? 'published' : 'published'
    }
  },
  {
    resolve: `gatsby-plugin-google-fonts`,
    options: {
      fonts: [
        // `Merriweather:400,700`,
        `Roboto:300,400,400i,700` // you can also specify font weights and styles
      ],
      display: 'swap'
    }
  },
  {
    resolve: `gatsby-plugin-i18n`,
    options: {
      langKeyDefault: 'en',
      langKeyForNull: 'en',
      prefixDefault: false,
      useLangKeyLayout: false,
    },
  },
  {
    resolve: 'gatsby-plugin-root-import',
    options: {
      src: path.join(__dirname, 'src'),
      pages: path.join(__dirname, 'src/pages'),
      layouts: path.join(__dirname, 'src/layouts'),
      components: path.join(__dirname, 'src/components'),
      component: path.join(__dirname, 'src/component'),
      styles: path.join(__dirname, 'src/styles'),
      templates: path.join(__dirname, 'src/templates'),
      utils: path.join(__dirname, 'src/utils'),
      images: path.join(__dirname, 'src/images'),
      route: path.join(__dirname, 'src/route'),
    }
  },
  {
    resolve: `gatsby-plugin-gdpr-cookies`,
    options: {
      googleAnalytics: {
        trackingId: 'G-4LMNE1TSSE', // leave empty if you want to disable the tracker
        cookieName: 'gatsby-gdpr-google-analytics', // default
        anonymize: true, // default
        allowAdFeatures: false // default
      },
      // googleTagManager: {
      //   trackingId: 'YOUR_GOOGLE_TAG_MANAGER_TRACKING_ID', // leave empty if you want to disable the tracker
      //   cookieName: 'gatsby-gdpr-google-tagmanager', // default
      //   dataLayerName: 'dataLayer', // default
      // },
      facebookPixel: {
        pixelId: '136572401640183', // leave empty if you want to disable the tracker
        cookieName: 'gatsby-gdpr-facebook-pixel', // default
      },
      // defines the environments where the tracking should be available  - default is ["production"]
      environments: ['production', 'development']
    },
  },
  `gatsby-plugin-preact`,
],
}
