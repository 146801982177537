import React, { useState } from 'react';
// import api from 'utils/api';

import SignUp from './Form.component';

const SignUpContainer = ({t}) => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    job: '',
    message: ''
  });

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = async (e) => {
    console.log('submit')
    let { fullname, email, job, message } = formData
    e.preventDefault();
    setLoading(true);

    let payload = {
      fullName: fullname,
      email,
      jobDescription: job,
      msg: message
    }

    try {
      // let response = await fetch('http://localhost:5000/api/site/sendContactEmail', { // testing
      let response = await fetch('https://app.farmair.io/api/site/sendContactEmail', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      setShowSuccessMsg(true)
      setTimeout(() => {
        setShowSuccessMsg(false)
      }, 3000)
      setFormData({...formData,
        fullname: '',
        email: '',
        job: '',
        message: ''
      })
      setLoading(false);
    } catch (err) {
      console.log(err)
      alert('Something went wrong. Please try again later')
      setLoading(false);
    }
  }

  const state = {
    showSuccessMsg,
    loading,
    formData,
    t
  }

  const containerFunctions = {
    onSubmit,
    onChange,
  };


  return (
      <SignUp
        // { ...this.props }
        { ...state }
        { ...containerFunctions }
      />
  );
}

export default SignUpContainer;