import React from 'react';
import { Spinner, Alert } from 'react-bootstrap'
import { Link } from "gatsby"
import './index.style.scss';
import { __, __link } from 'utils/i18n'
import { getLangLinkPrefix } from 'utils/lang'


const SignUp = (props) => {
  const { onChange, formData, onSubmit, t, showSuccessMsg, loading } = props
  const lang = getLangLinkPrefix(t);
  let { fullname, email, job, message } = formData

  return (
    <>
      <div className="form-wrap">
        <form onSubmit={onSubmit}>
          {showSuccessMsg ?
            <div className="alert-wrap fade-in">
              <Alert variant="success">
                {__('Message sent successfully! We will contact you soon')}
              </Alert>
            </div>
          : ''}
          <div className="form-group">
            <label htmlFor="fullname">{__("Full Name", t)}</label>
            <input id="fullname" name="fullname" type="text"
              onChange={onChange}
              value={fullname}
              required={true}
              className="form-control"
              disabled={loading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input id="email" name="email" type="email"
              onChange={onChange}
              value={email}
              required={true}
              className="form-control"
              disabled={loading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="job">{__("Job Description", t)}</label>
            <input id="job" name="job" type="text"
              onChange={onChange}
              value={job}
              required={true}
              className="form-control"
              disabled={loading}
            />
          </div>

          <textarea name="message" id="message" cols="30" rows="10"
            placeholder={__("Write your message", t)}
            value={message}
            onChange={onChange}
            required={true}
            disabled={loading}
            className="form-control">
          </textarea>

          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="agree-terms" required={true} />
            <label class="form-check-label" for="agree-terms">{__("I agree with the", t)}<Link to={`${lang}/${__link('privacy-policy', t)}`}>{__("Privacy Policy", t)}</Link></label>
          </div>

          <div className="btn-wrap">
            <button type="submit" className="m-btn m-btn-green w-100" disabled={loading}>
              {__("Send mail", t)}
            </button>
          </div>
          { loading ?
          <div className="spinner-wrap">
            <Spinner animation="border" role="status" size="xl">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          : ''}
        </form>
      </div>
    </>
  )

}

export default SignUp;