import Link from "gatsby-link"
import React from "react"
import logo from "images/logo.svg"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import globe from "images/globe.svg"
import unitedKingdom from "images/united-kingdom.svg"
import germany from "images/germany.svg"
import greece from "images/greece.svg"

import { getLangLinkPrefix } from "utils/lang"
import { __, __link } from "utils/i18n"

const stickyHeader = () => {
  /* sticky header show on scroll up */
  ;(function () {
    var doc = document.documentElement
    var w = window
    var prevScroll = w.scrollY || doc.scrollTop
    var curScroll
    var direction = 0
    var prevDirection = 0

    var header = document.getElementsByClassName("sticky-header")[0]
    var headerHeight = header.offsetHeight
    var startedScrollingUp

    var offsetToShowHeaderOnScrollUp = 200
    if (window.innerWidth <= 1200) {
      offsetToShowHeaderOnScrollUp = 120
    }

    var checkScroll = function () {
      curScroll = w.scrollY || doc.scrollTop

      if (curScroll > prevScroll) {
        direction = 2
        startedScrollingUp = null
      } else if (curScroll < prevScroll) {
        direction = 1
        if (!startedScrollingUp) {
          startedScrollingUp = curScroll
        }
      }
      if (direction !== prevDirection) {
        toggleHeader(direction, curScroll)
      }
      prevScroll = curScroll
    }

    var toggleHeader = function (direction, curScroll) {
      if (direction === 2 && curScroll > 2 * headerHeight) {
        header.classList.add("hide")
        prevDirection = direction
      } else if (
        direction === 1 &&
        startedScrollingUp - curScroll > offsetToShowHeaderOnScrollUp
      ) {
        header.classList.remove("hide")
        prevDirection = direction
      }
    }

    window.addEventListener("scroll", checkScroll)
  })()
}

const Navivation = ({ settings, t }) => {
  const lang = getLangLinkPrefix(t)
  let homeLink = lang ? lang : "/"

  React.useEffect(() => {
    stickyHeader()
  }, [])

  return (
    <header className="w-full bg-white sticky-header">
      <Navbar bg="white" expand="xl" sticky="top">
        <Link className="navbar-brand" to={`${homeLink}`}>
          <img src={logo} alt="farmAIr logo" className="logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link
                to={`${lang}/${__link("how-to-use-it", t)}`}
                className="nav-link"
              >
                {__("How to", t)}
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`${lang}/${__link("why-us", t)}`} className="nav-link">
                {__("Why us", t)}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`${lang}/${__link("our-technology", t)}`}
                className="nav-link"
              >
                {__("Our Tech", t)}
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link to={`${lang}/${__link('pricing', t)}`}>{__("Prices", t)}</Link>
            </li> */}
            <li className="nav-item">
              <Link to={`${lang}/${__link("contact", t)}`} className="nav-link">
                {__("Contact", t)}
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`${lang}/${__link("blog", t)}`} className="nav-link">
                {__("Blog", t)}
              </Link>
            </li>

            <li className="nav-item dropdown">
              <NavDropdown
                title={<img src={globe} alt="globe icon" />}
                id="collasible-nav-dropdown"
                className="languages"
              >
                <NavDropdown.Item href="/">
                  <img src={unitedKingdom} alt="united kingdom flag" />
                  <span>English</span>
                </NavDropdown.Item>
                <NavDropdown.Item href="/el/">
                  <img src={greece} alt="greek flag" />
                  <span>Ελληνικά</span>
                </NavDropdown.Item>
              </NavDropdown>
            </li>
            <li className="nav-item action signin">
              <a
                href="https://app.farmair.io/login"
                rel="noreferrer"
                target="_blank"
                className="m-btn m-btn-white m-btn-sm"
              >
                {__("Sign In", t)}
              </a>
            </li>
            <li className="nav-item action">
              <a
                href="https://app.farmair.io/signup"
                rel="noreferrer"
                target="_blank"
                className="m-btn m-btn-green m-btn-sm"
              >
                {__("Register", t)}
              </a>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Navivation
