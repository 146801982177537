// export { default } from './Form.container';
import Form from './Form.container.js'
import React from "react"
import './index.style.scss'
import logoLeaf from 'images/logoLeaf.svg'
import { __ } from 'utils/i18n'

const Details = ({t}) => {
  return (
    <div className="details-wrap">
      <div className="inner">
        {/* <div className="box">
          <h5>{__("Offices:", t)}</h5>
          <p>Middletown, Delaware US</p>
        </div> */}
        <div className="box">
          <h5>{__("Sales Support:", t)}</h5>
          <a href={`tel:${__("Tel Number", t)}`}>{__("Tel:", t)} {__("Tel Number", t)}</a>
          <a href="mailto:sales@farmair.io">Email: sales@farmair.io</a>
        </div>

      </div>
    </div>
  )
}

export default function ContactForm({ location, t }) {
  return (
    <div className="section-contact-form fade-in">
      <div className="contact-form-wrap">
      <div className="header c-white container">
      <h2>{__("Contact us", t)}</h2>
      <p className="subtitle">{__("Contact us Subtitle", t)}</p>
    </div>
      </div>

      <div className="inner container">
          <div className="row">
            <div className="col-md-6">
              <Form t={t} />
            </div>
            <div className="col-md-6">
              <Details t={t} />
            </div>
          </div>
          <div className="leaf-icon">
            <img src={logoLeaf} alt="leaf group" />
          </div>
      </div>
    </div>
  )
}